import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from '@reduxjs/toolkit/dist/devtoolsExtension';
import { history } from '../routes/history';
import { rootSaga } from './rootSaga';
import createRootReducer from './rootReducer';
import { persistConfig } from './persistConfig';

// ==============================================================
const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

const sagaMiddleware = createSagaMiddleware();

const syncWithLocalStorage = (store: any) => (next: any) => (action: any) => {
	const result = next(action);

	if (
		action.type === '@@auth/loginSuccess' ||
		action.type === '@@auth/authInitState' ||
		action.type === '@@auth/getRefreshTokenSuccess'
	) {
		localStorage.setItem('accessToken', store.getState().auth.accessToken);
	}
	return result;
};

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(sagaMiddleware, routerMiddleware(history), syncWithLocalStorage),
	devTools: true,
	preloadedState: {},
});

// const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));
// export const store = createStore(rootReducer);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
