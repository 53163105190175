import { FC, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { getActions2FALoading } from 'redux/reducers/settings/selectors';
import { IEnter2FACodeProps } from './types';

export const Enter2FACode: FC<IEnter2FACodeProps> = ({ onSubmit, onClose, action }) => {
	const actsLoading = useSelector(getActions2FALoading);

	const [code, setCode] = useState('');

	const handleChange2FA = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 6) return;
		setCode(e.target.value);
	};

	const isGoogle = action === 'google-off';

	return (
		<div className="popup popup--padding-type2 popup--smaller">
			<div className="popup-header">
				<p className="popup-header__title">Enter 2FA code</p>

				<button className="popup-close" type="button" onClick={onClose}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7 7L17 17"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7 17L17 7"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>

			<div className="popup-body">
				<div className="form-text form-text--center">
					<p>
						For security we must confirm that you are definitely trying to change your settings.
					</p>
				</div>

				<div className="form-group">
					<div className="input">
						<p className="input__name">{isGoogle ? 'Authenticator' : 'Email'} Code</p>

						<div className="input-wrapper">
							<input
								className="input-item"
								placeholder="6 digit code"
								type="number"
								value={code}
								onChange={handleChange2FA}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="popup-footer">
				<button className="button button--type3 button--full-width" type="button" onClick={onClose}>
					Cancel
				</button>

				<button
					className="button button--full-width"
					type="button"
					onClick={() => onSubmit(code)}
					disabled={!code || actsLoading}
				>
					Confirm
				</button>
			</div>
		</div>
	);
};
