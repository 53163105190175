import { useEffect, useState } from 'react';

type WindowSize = {
	width: undefined | number;
	height: undefined | number;
};

const useWindowResize = () => {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout>;
		if (typeof window !== 'undefined') {
			const handleResize = () => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			};

			window.addEventListener('resize', () => {
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					handleResize();
				}, 300);
			});
			handleResize();

			return () => {
				window.removeEventListener('resize', handleResize);
				clearTimeout(timeout);
			};
		}
		return () => {};
	}, []);

	return windowSize;
};

export default useWindowResize;
